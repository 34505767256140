import React from 'react';
import {
  Icon,
  IconButton,
  Pressable,
  RadioButton,
  RadioButtonGroup,
  ScreenContainer,
  SimpleStyleMasonryFlashList,
  TextInput,
  VStack,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';
import * as GlobalStyles from '../GlobalStyles.js';
import * as SupabaseApi from '../apis/SupabaseApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as CustomCode from '../custom-files/CustomCode';
import palettes from '../themes/palettes';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import imageSource from '../utils/imageSource';
import useWindowDimensions from '../utils/useWindowDimensions';

const SwiftHistoryMediaScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const [currentPeople, setCurrentPeople] = React.useState('');
  const [currentURL, setCurrentURL] = React.useState('');
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalWebVisible, setModalWebVisible] = React.useState(false);
  const [searchPeople, setSearchPeople] = React.useState('');
  const [tempSearch, setTempSearch] = React.useState('');
  const [typeMedia, setTypeMedia] = React.useState('');
  const [radioButtonGroupValue, setRadioButtonGroupValue] =
    React.useState(undefined);
  const FilterList = list => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */
    console.log(list, tempSearch);
    const newList = list.filter(
      item =>
        item.name.toLowerCase().includes(tempSearch) &&
        item.type.toLowerCase().includes(typeMedia)
    );

    return newList;
  };

  const formatMediaType = text => {
    // Type the code for the body of your function or hook here.
    // Functions can be triggered via Button/Touchable actions.
    // Hooks are run per ReactJS rules.

    /* String line breaks are accomplished with backticks ( example: `line one
line two` ) and will not work with special characters inside of quotes ( example: "line one line two" ) */

    //return text.charAt(0).toUpperCase() + text.slice(1);

    return text.replace(/^./, text[0].toUpperCase());
  };

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* Media Fetch */}
      <SupabaseApi.FetchMediaGET>
        {({ loading, error, data, refetchMedia }) => {
          const mediaFetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Search */}
              <View
                style={StyleSheet.applyWidth(
                  { padding: '2%' },
                  dimensions.width
                )}
              >
                <View>
                  <View>
                    {/* Search Field */}
                    <TextInput
                      autoCapitalize={'none'}
                      changeTextDelay={500}
                      onChangeText={newSearchFieldValue => {
                        try {
                          setTempSearch(newSearchFieldValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      webShowOutline={true}
                      {...GlobalStyles.TextInputStyles(theme)['Text Input']
                        .props}
                      autoComplete={'name'}
                      autoCorrect={false}
                      clearButtonMode={'always'}
                      editable={true}
                      placeholder={'Search...'}
                      placeholderTextColor={theme.colors.text.light}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextInputStyles(theme)['Text Input']
                            .style,
                          {
                            borderBottomWidth: null,
                            borderColor: [
                              {
                                minWidth: Breakpoints.Mobile,
                                value: theme.colors.text.medium,
                              },
                              {
                                minWidth: Breakpoints.BigScreen,
                                value: theme.colors.text.medium,
                              },
                            ],
                            borderLeftWidth: null,
                            borderRightWidth: null,
                            borderTopWidth: null,
                            borderWidth: 1,
                            fontSize: [
                              { minWidth: Breakpoints.Desktop, value: 22 },
                              { minWidth: Breakpoints.Laptop, value: 18 },
                              { minWidth: Breakpoints.Mobile, value: 16 },
                            ],
                            padding: 8,
                            paddingBottom: null,
                            paddingLeft: null,
                            paddingRight: null,
                            paddingTop: null,
                          }
                        ),
                        dimensions.width
                      )}
                      value={tempSearch}
                    />
                    <RadioButtonGroup
                      onValueChange={newRadioButtonGroupValue => {
                        const radioButtonGroupValue = newRadioButtonGroupValue;
                        try {
                          setTypeMedia(newRadioButtonGroupValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      direction={'vertical'}
                      value={radioButtonGroupValue}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flexDirection: 'row',
                            justifyContent: 'space-evenly',
                          },
                          dimensions.width
                        )}
                      >
                        {/* All */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.Brand.Black,
                                  fontFamily: 'System',
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 22,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                  ],
                                  fontWeight: '400',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'All'}
                          </Text>
                          <RadioButton
                            selectedIcon={'MaterialIcons/radio-button-checked'}
                            size={24}
                            unselectedIcon={
                              'MaterialIcons/radio-button-unchecked'
                            }
                            color={palettes.Brand.Black}
                            unselectedColor={theme.colors.text.medium}
                          />
                        </View>
                        {/* Video */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.Brand.Black,
                                  fontFamily: 'System',
                                  fontSize: [
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 22,
                                    },
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                  ],
                                  fontWeight: '400',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Video'}
                          </Text>
                          <RadioButton
                            selectedIcon={'MaterialIcons/radio-button-checked'}
                            size={24}
                            unselectedIcon={
                              'MaterialIcons/radio-button-unchecked'
                            }
                            color={palettes.Brand.Black}
                            unselectedColor={theme.colors.text.medium}
                            value={'video'}
                          />
                        </View>
                        {/* Audio */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'column' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.Brand.Black,
                                  fontFamily: 'System',
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 22,
                                    },
                                  ],
                                  fontWeight: '400',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Audio'}
                          </Text>
                          <RadioButton
                            selectedIcon={'MaterialIcons/radio-button-checked'}
                            size={24}
                            unselectedIcon={
                              'MaterialIcons/radio-button-unchecked'
                            }
                            color={palettes.Brand.Black}
                            unselectedColor={theme.colors.text.medium}
                            value={'audio'}
                          />
                        </View>
                        {/* Print */}
                        <View
                          style={StyleSheet.applyWidth(
                            { alignItems: 'center', flexDirection: 'column' },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            selectable={false}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: palettes.Brand.Black,
                                  fontFamily: 'System',
                                  fontSize: [
                                    { minWidth: Breakpoints.Mobile, value: 16 },
                                    {
                                      minWidth: Breakpoints.Desktop,
                                      value: 22,
                                    },
                                  ],
                                  fontWeight: '400',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Print'}
                          </Text>
                          <RadioButton
                            selectedIcon={'MaterialIcons/radio-button-checked'}
                            size={24}
                            unselectedIcon={
                              'MaterialIcons/radio-button-unchecked'
                            }
                            color={palettes.Brand.Black}
                            unselectedColor={theme.colors.text.medium}
                            value={'print'}
                          />
                        </View>
                      </View>
                    </RadioButtonGroup>
                  </View>
                </View>
              </View>
              {/* Disclaimer Text */}
              <View>
                {/* Large Screen Text */}
                <>
                  {!(dimensions.width >= Breakpoints.Desktop) ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          marginBottom: 10,
                          marginLeft: [
                            { minWidth: Breakpoints.Mobile, value: 10 },
                            { minWidth: Breakpoints.Desktop, value: 25 },
                          ],
                          marginRight: [
                            { minWidth: Breakpoints.Mobile, value: 10 },
                            { minWidth: Breakpoints.Desktop, value: 25 },
                          ],
                        },
                        dimensions.width
                      )}
                    >
                      {/* External Text */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.Brand.Black,
                              fontFamily: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'System',
                                },
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: 'System',
                                },
                              ],
                              fontSize: {
                                minWidth: Breakpoints.Desktop,
                                value: 20,
                              },
                              fontStyle: {
                                minWidth: Breakpoints.Desktop,
                                value: 'italic',
                              },
                              fontWeight: [
                                { minWidth: Breakpoints.Mobile, value: '400' },
                                { minWidth: Breakpoints.Desktop, value: '400' },
                              ],
                              textAlign: [
                                { minWidth: Breakpoints.Mobile, value: 'left' },
                                {
                                  minWidth: Breakpoints.Desktop,
                                  value: 'center',
                                },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'Media may open in external windows or programs. The Museum is not responsible for the accuracy, currency, or reliability of third-party content.'
                        }
                      </Text>
                    </View>
                  )}
                </>
                {/* Small Screen Text */}
                <>
                  {dimensions.width >= Breakpoints.Desktop ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { marginBottom: 10, marginLeft: 10, marginRight: 10 },
                        dimensions.width
                      )}
                    >
                      {/* External Text */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.Brand.Black,
                              fontFamily: 'System',
                              fontSize: {
                                minWidth: Breakpoints.Desktop,
                                value: 20,
                              },
                              fontWeight: '400',
                              textAlign: 'left',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Media may open in external windows or programs.'}
                      </Text>
                      {/* Third-Party Text */}
                      <Text
                        accessible={true}
                        selectable={false}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              color: palettes.Brand.Black,
                              fontFamily: 'System',
                              fontSize: [
                                { minWidth: Breakpoints.Desktop, value: 20 },
                                { minWidth: Breakpoints.Mobile, value: 14 },
                              ],
                              fontStyle: 'italic',
                              fontWeight: '400',
                              textAlign: 'right',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          'The Museum is not responsible for the accuracy, currency, or reliability of third-party content.'
                        }
                      </Text>
                    </View>
                  )}
                </>
              </View>
              {/* Masonry List - Small */}
              <>
                {dimensions.width >= Breakpoints.Tablet ? null : (
                  <SimpleStyleMasonryFlashList
                    data={FilterList(mediaFetchData)}
                    keyExtractor={(masonryListSmallData, index) =>
                      masonryListSmallData?.id
                    }
                    listKey={'YTNoFiuz'}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const masonryListSmallData = item;
                      return (
                        <Pressable
                          onPress={() => {
                            const handler = async () => {
                              console.log('Pressable ON_PRESS Start');
                              let error = null;
                              try {
                                console.log('Start ON_PRESS:0 FETCH_REQUEST');
                                /* hidden 'API Request' action */ console.log(
                                  'Complete ON_PRESS:0 FETCH_REQUEST'
                                );
                                console.log('Start ON_PRESS:1 SET_VARIABLE');
                                /* hidden 'Set Variable' action */ console.log(
                                  'Complete ON_PRESS:1 SET_VARIABLE'
                                );
                                console.log('Start ON_PRESS:2 SET_VARIABLE');
                                /* hidden 'Set Variable' action */ console.log(
                                  'Complete ON_PRESS:2 SET_VARIABLE'
                                );
                                console.log('Start ON_PRESS:3 SET_VARIABLE');
                                /* hidden 'Set Variable' action */ console.log(
                                  'Complete ON_PRESS:3 SET_VARIABLE'
                                );
                                console.log('Start ON_PRESS:4 NAVIGATE');
                                /* hidden 'Navigate' action */ console.log(
                                  'Complete ON_PRESS:4 NAVIGATE'
                                );
                                console.log(
                                  'Start ON_PRESS:5 WEBBROWSER_OPENBROWSERASYNC'
                                );
                                await WebBrowser.openBrowserAsync(
                                  `${masonryListSmallData?.url}`
                                );
                                console.log(
                                  'Complete ON_PRESS:5 WEBBROWSER_OPENBROWSERASYNC'
                                );
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'Pressable ON_PRESS Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'center',
                                alignItems: 'center',
                                alignSelf: 'center',
                                borderRadius: 10,
                                borderWidth: 1,
                                flexWrap: 'wrap',
                                margin: 2,
                                padding: 5,
                                paddingLeft: 10,
                                width: '90%',
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!masonryListSmallData?.image ? null : (
                                <Image
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  resizeMode={'center'}
                                  source={imageSource(
                                    `${masonryListSmallData?.image}`
                                  )}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        minHeight: [
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 250,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 200,
                                          },
                                        ],
                                        minWidth: [
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 250,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 200,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            {/* Type */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'button'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.Brand.Black,
                                    fontFamily: 'System',
                                    fontSize: 16,
                                    fontStyle: 'italic',
                                    fontWeight: '400',
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {formatMediaType(masonryListSmallData?.type)}
                            </Text>

                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'button'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.Brand.Black,
                                    fontFamily: 'System',
                                    fontSize: 16,
                                    fontWeight: '400',
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {masonryListSmallData?.name}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    estimatedItemSize={76}
                    numColumns={2}
                    showsHorizontalScrollIndicator={false}
                  />
                )}
              </>
              {/* Masonry List - Large */}
              <>
                {!(dimensions.width >= Breakpoints.Tablet) ? null : (
                  <SimpleStyleMasonryFlashList
                    data={FilterList(mediaFetchData)}
                    keyExtractor={(masonryListLargeData, index) =>
                      masonryListLargeData?.id
                    }
                    listKey={'wdYKzggF'}
                    onEndReachedThreshold={0.5}
                    renderItem={({ item, index }) => {
                      const masonryListLargeData = item;
                      return (
                        <Pressable
                          onPress={() => {
                            const handler = async () => {
                              console.log('Pressable ON_PRESS Start');
                              let error = null;
                              try {
                                console.log('Start ON_PRESS:0 FETCH_REQUEST');
                                /* hidden 'API Request' action */ console.log(
                                  'Complete ON_PRESS:0 FETCH_REQUEST'
                                );
                                console.log('Start ON_PRESS:1 SET_VARIABLE');
                                /* hidden 'Set Variable' action */ console.log(
                                  'Complete ON_PRESS:1 SET_VARIABLE'
                                );
                                console.log('Start ON_PRESS:2 SET_VARIABLE');
                                /* hidden 'Set Variable' action */ console.log(
                                  'Complete ON_PRESS:2 SET_VARIABLE'
                                );
                                console.log('Start ON_PRESS:3 SET_VARIABLE');
                                /* hidden 'Set Variable' action */ console.log(
                                  'Complete ON_PRESS:3 SET_VARIABLE'
                                );
                                console.log('Start ON_PRESS:4 NAVIGATE');
                                /* hidden 'Navigate' action */ console.log(
                                  'Complete ON_PRESS:4 NAVIGATE'
                                );
                                console.log(
                                  'Start ON_PRESS:5 WEBBROWSER_OPENBROWSERASYNC'
                                );
                                await WebBrowser.openBrowserAsync(
                                  `${masonryListLargeData?.url}`
                                );
                                console.log(
                                  'Complete ON_PRESS:5 WEBBROWSER_OPENBROWSERASYNC'
                                );
                              } catch (err) {
                                console.error(err);
                                error = err.message ?? err;
                              }
                              console.log(
                                'Pressable ON_PRESS Complete',
                                error ? { error } : 'no error'
                              );
                            };
                            handler();
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignContent: 'center',
                                alignItems: [
                                  {
                                    minWidth: Breakpoints.Mobile,
                                    value: 'stretch',
                                  },
                                  {
                                    minWidth: Breakpoints.Tablet,
                                    value: 'center',
                                  },
                                ],
                                alignSelf: 'center',
                                borderRadius: 10,
                                borderWidth: 1,
                                flexWrap: 'wrap',
                                margin: 2,
                                padding: 5,
                                paddingLeft: 10,
                                width: '90%',
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!masonryListLargeData?.image ? null : (
                                <Image
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  resizeMode={'center'}
                                  source={imageSource(
                                    `${masonryListLargeData?.image}`
                                  )}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        minHeight: [
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 250,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 150,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 200,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                        ],
                                        minWidth: [
                                          {
                                            minWidth: Breakpoints.BigScreen,
                                            value: 250,
                                          },
                                          {
                                            minWidth: Breakpoints.Desktop,
                                            value: 200,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 150,
                                          },
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 50,
                                          },
                                        ],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              )}
                            </>
                            {/* Type */}
                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'button'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.Brand.Black,
                                    fontFamily: 'System',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                    ],
                                    fontStyle: 'italic',
                                    fontWeight: '400',
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {formatMediaType(masonryListLargeData?.type)}
                            </Text>

                            <Text
                              accessible={true}
                              selectable={false}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              accessibilityRole={'text'}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    color: palettes.Brand.Black,
                                    fontFamily: 'System',
                                    fontSize: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 16,
                                      },
                                      {
                                        minWidth: Breakpoints.Desktop,
                                        value: 22,
                                      },
                                    ],
                                    fontWeight: '400',
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {masonryListLargeData?.name}
                            </Text>
                          </View>
                        </Pressable>
                      );
                    }}
                    showsVerticalScrollIndicator={true}
                    estimatedItemSize={76}
                    numColumns={3}
                    showsHorizontalScrollIndicator={false}
                  />
                )}
              </>
            </>
          );
        }}
      </SupabaseApi.FetchMediaGET>
      <Utils.CustomCodeErrorBoundary>
        <CustomCode.ChangeStackNavigation navigation={props.navigation} />
      </Utils.CustomCodeErrorBoundary>
    </ScreenContainer>
  );
};

export default withTheme(SwiftHistoryMediaScreen);

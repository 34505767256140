/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';
import { Platform } from 'react-native';

function renderLinkingPrefix() {
  try {
    return Linking.createURL('/');
  } catch (e) {
    return 'draftbit://';
  }
}

const prefix = renderLinkingPrefix();

const linking = {
  enabled: Platform.OS === 'web' ? false : true,
  prefixes: [prefix],
  config: {
    screens: {
      StackNavigator: {
        screens: {
          IndexScreen: {
            screens: {
              IndexScreen: {
                path: 'IndexScreen',
              },
            },
          },

          SwiftHistoryActivitiesScreen: {
            screens: {
              SwiftHistoryActivitiesScreen: {
                path: 'SwiftHistoryActivitiesScreen',
              },
            },
          },

          SwiftHistoryBrowserScreen: {
            screens: {
              SwiftHistoryBrowserScreen: {
                path: 'SwiftHistoryBrowserScreen/:url?',
              },
            },
          },

          SwiftHistoryInformationScreen: {
            screens: {
              SwiftHistoryInformationScreen: {
                path: 'SwiftHistoryInformationScreen/:description?/:name?/:image?/:url?',
              },
            },
          },

          SwiftHistoryMapScreen: {
            screens: {
              SwiftHistoryMapScreen: {
                path: 'SwiftHistoryMapScreen',
              },
            },
          },

          SwiftHistoryMediaScreen: {
            screens: {
              SwiftHistoryMediaScreen: {
                path: 'SwiftHistoryMediaScreen',
              },
            },
          },

          SwiftHistoryPeopleScreen: {
            screens: {
              SwiftHistoryPeopleScreen: {
                path: 'SwiftHistoryPeopleScreen',
              },
            },
          },

          SwiftHistoryPlacesScreen: {
            screens: {
              SwiftHistoryPlacesScreen: {
                path: 'SwiftHistoryPlacesScreen',
              },
            },
          },

          SwiftHistoryResourcesScreen: {
            screens: {
              SwiftHistoryResourcesScreen: {
                path: 'SwiftHistoryResourcesScreen',
              },
            },
          },
        },
      },
    },
  },
};

export default linking;

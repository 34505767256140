import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import {
  encodeQueryParam,
  renderParam,
  renderQueryString,
} from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const activityGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['or'] =
    '(type.eq.activity,type.eq.jigsaw,type.eq.sliding,type.eq.wordsearch)';
  paramsDict['order'] = 'name,type';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useActivityGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['activities', args],
    () => activityGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchActivityGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useActivityGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchActivity: refetch });
};

export const agentsofdeteriorationGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.treasure';
  paramsDict['description'] =
    'eq.Canadian Conservation Institute Agents of Deterioration';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useAgentsofdeteriorationGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['agents', args],
    () => agentsofdeteriorationGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchAgentsofdeteriorationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAgentsofdeteriorationGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchAgentsofdeterioration: refetch,
  });
};

export const cciGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.treasure';
  paramsDict['or'] =
    '(description.eq.Canadian Conservation Institute Notes,description.eq.Canadian Conservation Institute Technical Bulletins)';
  paramsDict['order'] = 'description,name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useCciGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['ccis', args], () => cciGET(Constants, args, handlers), {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
  });
};

export const FetchCciGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useCciGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCci: refetch });
};

export const conservatorGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.treasure';
  paramsDict['description'] = 'eq.Find A Conservator';
  paramsDict['order'] = 'name.desc';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useConservatorGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['conservators', args],
    () => conservatorGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchConservatorGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useConservatorGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchConservator: refetch });
};

export const contactusGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.staff';
  paramsDict['order'] = 'id.desc';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/contacts${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useContactusGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['contactuses', args],
    () => contactusGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchContactusGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useContactusGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchContactus: refetch });
};

export const databaseArchivesGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['id'] = 'eq.d0f7e2a1-df93-48db-a790-5fdad4a406ad';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDatabaseArchivesGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['databaseArchive', args],
    () => databaseArchivesGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['databaseArchives']),
    }
  );
};

export const FetchDatabaseArchivesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDatabaseArchivesGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDatabaseArchives: refetch });
};

export const databaseLibraryGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['id'] = 'eq.1c3621d2-5576-425d-934d-011324a45502';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDatabaseLibraryGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['databaseLibrary', args],
    () => databaseLibraryGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['databaseLibraries']),
    }
  );
};

export const FetchDatabaseLibraryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDatabaseLibraryGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDatabaseLibrary: refetch });
};

export const databaseMuseumGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['id'] = 'eq.62e19e24-d101-446e-89f4-07459c480039';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDatabaseMuseumGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['databaseMuseum', args],
    () => databaseMuseumGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['databaseMuseums']),
    }
  );
};

export const FetchDatabaseMuseumGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDatabaseMuseumGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDatabaseMuseum: refetch });
};

export const databasesGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.database';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useDatabasesGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['databases', args],
    () => databasesGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchDatabasesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useDatabasesGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchDatabases: refetch });
};

export const hoursGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.hours';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useHoursGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(['hour', args], () => hoursGET(Constants, args, handlers), {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    onSuccess: () => queryClient.invalidateQueries(['hours']),
  });
};

export const FetchHoursGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useHoursGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchHours: refetch });
};

export const jigsawGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.jigsaw';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useJigsawGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['jigsaws', args],
    () => jigsawGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchJigsawGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useJigsawGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchJigsaw: refetch });
};

export const mapActiveGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['order'] = 'title';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/map${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMapActiveGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['maps', args],
    () => mapActiveGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchMapActiveGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMapActiveGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMapActive: refetch });
};

export const mediaGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['or'] =
    '(type.eq.media,type.eq.video,type.eq.audio,type.eq.print)';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMediaGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['media', args], () => mediaGET(Constants, args, handlers), {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
  });
};

export const FetchMediaGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMediaGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMedia: refetch });
};

export const mediaModalGET = async (
  Constants,
  { currentMediaAPI },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  if (currentMediaAPI !== undefined) {
    paramsDict['additional_id'] = `eq.${renderParam(currentMediaAPI)}`;
  }
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useMediaModalGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['media', args],
    () => mediaModalGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchMediaModalGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  currentMediaAPI,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMediaModalGET(
    { currentMediaAPI },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMediaModal: refetch });
};

export const npsGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.treasure';
  paramsDict['description'] = 'eq.National Park Service Conserve-O-Grams';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useNpsGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(['nps', args], () => npsGET(Constants, args, handlers), {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
  });
};

export const FetchNpsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useNpsGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchNps: refetch });
};

export const onlineResourcesGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.resource';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useOnlineResourcesGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['resources', args],
    () => onlineResourcesGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchOnlineResourcesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useOnlineResourcesGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchOnlineResources: refetch });
};

export const peopleActiveGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/people${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePeopleActiveGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['people', args],
    () => peopleActiveGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchPeopleActiveGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePeopleActiveGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPeopleActive: refetch });
};

export const peopleModalGET = async (
  Constants,
  { currentPeopleAPI },
  handlers = {}
) => {
  const paramsDict = {};
  if (currentPeopleAPI !== undefined) {
    paramsDict['id'] = `eq.${renderParam(currentPeopleAPI)}`;
  }
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/people${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePeopleModalGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['modal', args],
    () => peopleModalGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['modals']),
    }
  );
};

export const FetchPeopleModalGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  currentPeopleAPI,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePeopleModalGET(
    { currentPeopleAPI },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPeopleModal: refetch });
};

export const peopleSearchdynamicGET = async (
  Constants,
  { peoplesearch },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  if (peoplesearch !== undefined) {
    paramsDict['name'] = `ilike.*${renderParam(peoplesearch)}*`;
  }
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/people${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePeopleSearchdynamicGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['people', args],
    () => peopleSearchdynamicGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchPeopleSearchdynamicGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  peoplesearch,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePeopleSearchdynamicGET(
    { peoplesearch },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchPeopleSearchdynamic: refetch,
  });
};

export const placesActiveGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/places${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePlacesActiveGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['places', args],
    () => placesActiveGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchPlacesActiveGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePlacesActiveGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPlacesActive: refetch });
};

export const placesActiveTentativeGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['or'] = '(status.eq.active,status.eq.tentative)';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/places${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePlacesActiveTentativeGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['places', args],
    () => placesActiveTentativeGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchPlacesActiveTentativeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePlacesActiveTentativeGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchPlacesActiveTentative: refetch,
  });
};

export const placesInformationGET = async (
  Constants,
  { currentID },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  if (currentID !== undefined) {
    paramsDict['id'] = `eq.${renderParam(currentID)}`;
  }
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/places${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePlacesInformationGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['information', args],
    () => placesInformationGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['information']),
    }
  );
};

export const FetchPlacesInformationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  currentID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePlacesInformationGET(
    { currentID },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPlacesInformation: refetch });
};

export const placesModalGET = async (
  Constants,
  { currentPlaceAPI },
  handlers = {}
) => {
  const paramsDict = {};
  if (currentPlaceAPI !== undefined) {
    paramsDict['id'] = `eq.${renderParam(currentPlaceAPI)}`;
  }
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/places${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePlacesModalGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['modal', args],
    () => placesModalGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['modals']),
    }
  );
};

export const FetchPlacesModalGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  currentPlaceAPI,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePlacesModalGET(
    { currentPlaceAPI },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPlacesModal: refetch });
};

export const placesSearchdynamicGET = async (
  Constants,
  { placesearch },
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  if (placesearch !== undefined) {
    paramsDict['name'] = `ilike.*${renderParam(placesearch)}*`;
  }
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/places${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePlacesSearchdynamicGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['places', args],
    () => placesSearchdynamicGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchPlacesSearchdynamicGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
  placesearch,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePlacesSearchdynamicGET(
    { placesearch },
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchPlacesSearchdynamic: refetch,
  });
};

export const policiesGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.policy';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const usePoliciesGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['policies', args],
    () => policiesGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchPoliciesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = usePoliciesGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPolicies: refetch });
};

export const requestsGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.request';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useRequestsGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['requests', args],
    () => requestsGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchRequestsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useRequestsGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchRequests: refetch });
};

export const researchGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.research';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useResearchGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['research', args],
    () => researchGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchResearchGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useResearchGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchResearch: refetch });
};

export const resourcesLandAckGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['id'] = 'eq.8c1dc178-c30d-45d6-8c38-b39f66e14f20';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useResourcesLandAckGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['acknowledgement', args],
    () => resourcesLandAckGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['acknowledgements']),
    }
  );
};

export const FetchResourcesLandAckGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useResourcesLandAckGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchResourcesLandAck: refetch });
};

export const slidingGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.sliding';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useSlidingGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['slidings', args],
    () => slidingGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchSlidingGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSlidingGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSliding: refetch });
};

export const treasuresGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.treasure';
  paramsDict['order'] = 'description,name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useTreasuresGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['treasures', args],
    () => treasuresGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchTreasuresGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useTreasuresGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchTreasures: refetch });
};

export const welcomeGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['id'] = 'eq.61756ba6-9c0e-4fe2-9425-18116c325923';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useWelcomeGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['welcome', args],
    () => welcomeGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      onSuccess: () => queryClient.invalidateQueries(['welcomes']),
    }
  );
};

export const FetchWelcomeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useWelcomeGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchWelcome: refetch });
};

export const welcometranslationsGET = async (
  Constants,
  _args,
  handlers = {}
) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.welcome';
  paramsDict['order'] = 'name';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/admin${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useWelcometranslationsGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['welcometranslations', args],
    () => welcometranslationsGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchWelcometranslationsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useWelcometranslationsGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchWelcometranslations: refetch,
  });
};

export const wordsearchGET = async (Constants, _args, handlers = {}) => {
  const paramsDict = {};
  paramsDict['status'] = 'eq.active';
  paramsDict['type'] = 'eq.wordsearch';
  paramsDict['order'] = 'additional_id';
  const url = `https://ltbcrtrbpoxrxzhcchsq.supabase.co/rest/v1/resources${renderQueryString(
    paramsDict
  )}`;
  const options = {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization:
        'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
      'Content-Type': 'application/json',
      apikey:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imx0YmNydHJicG94cnh6aGNjaHNxIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1OTQyMDgsImV4cCI6MjAwOTE3MDIwOH0.HKYfHVKIHrVKZwzzSCghZmEHW0V2N8T37I4EYbSm-H4',
    }),
  };
  const res = await fetch(url, options);
  return handleResponse(res, handlers);
};

export const useWordsearchGET = (
  args = {},
  {
    refetchInterval,
    refetchOnWindowFocus,
    refetchOnMount,
    refetchOnReconnect,
    retry,
    staleTime,
    handlers = {},
  } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['wordsearches', args],
    () => wordsearchGET(Constants, args, handlers),
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
    }
  );
};

export const FetchWordsearchGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  refetchOnWindowFocus,
  refetchOnMount,
  refetchOnReconnect,
  retry,
  staleTime,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useWordsearchGET(
    {},
    {
      refetchInterval,
      refetchOnWindowFocus,
      refetchOnMount,
      refetchOnReconnect,
      retry,
      staleTime,
      handlers: { onData, ...handlers },
    }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchWordsearch: refetch });
};
